
import { fetchPage, Page } from "../serviceClients/pageWebServices";
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
import { pushError } from "@/core";
import { AccountType } from '@/register/registerModel';
import VueI18n from 'vue-i18n';

@Component
export default class HowItWorksComponent extends Vue {
  isLoadingAssistant = false;
  isLoadingClient = false;

  assistantPage: Page = {} as Page;
  clientPage: Page = {} as Page;

  activeTabIndex: number = 0;

  get bannerClassName(): string {
    return this.activeTabIndex === 0? 'assistant': 'client'
  }

  get breadcrumbsList(): ({ text: string | VueI18n.LocaleMessages; href: string })[] {
    return  [
      {
        text: this.$t('Home'),
        href: '/'
      },
      {
        text: this.$t('How it works'),
        href: '#'
      },
      {
        text: this.activeTabIndex === 0? this.$t('Assistant'): this.$t('Client'),
        href: '#'
      }
    ]
  }

  get loggedUserIsClient(): boolean {
    if (!store.getters.module.user) {
      return false
    }

    return store.getters.module.user!.accountType === AccountType.Client;
  }

  get clientPageText() {
    return this.clientPage?.text;
  }

  get clientPageTitle() {
    return this.clientPage?.title;
  }

  get assistantPageText() {
    return this.assistantPage?.text;
  }

  get assistantPageTitle() {
    return this.assistantPage?.title;
  }

  setActiveTab(value: number): void {
    this.activeTabIndex = value
  }

  async mounted() {
    store.commit.module.setActiveModuleName(this.$t("How it works?"));

    await Promise.all([this.loadAssistant(), this.loadClient()]);

    if (this.loggedUserIsClient) {
      this.setActiveTab(1)
    }

    if (this.$route.query.type) {
        this.activeTabIndex = this.$route.query.type === 'client' ? 1 : 0
    }
  }

  async loadAssistant() {
    this.isLoadingAssistant = true;
    try {
      this.assistantPage = (await fetchPage("HowItWorksAssistant")).data;
    } catch (error) {
      pushError(error.response?.data?.error || error);
    } finally {
      this.isLoadingAssistant = false;
    }
  }

  async loadClient() {
    this.isLoadingClient = true;
    try {
      this.clientPage = (await fetchPage("HowItWorksClient")).data;
    } catch (error) {
      pushError(error.response?.data?.error || error);
    } finally {
      this.isLoadingClient = false;
    }
  }
}
